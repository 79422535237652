
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  onIonViewDidLeave,
  toastController,
} from '@ionic/vue';
import { defineComponent, inject, ref, Ref, watch, onMounted } from 'vue';
import axios from 'axios';
import PasswordMeter from 'vue-simple-password-meter';
import MessageBox from '@/components/MessageBox.vue';
import { useRouter, useRoute } from 'vue-router';
import { throttle } from 'lodash';

export default defineComponent({
  name: 'ResetPassword',
  components: {
    MessageBox,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    PasswordMeter,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const msgBox: Ref<typeof MessageBox | null> = ref(null);
    const usernameText = ref('');
    const passwordResetCodeText = ref('');
    const passwordText = ref('');
    const confirmPasswordText = ref('');
    const passwordStrength = ref('');
    const getUsername: () => Ref<string> = inject('getUsername', () => ref(''));

    onIonViewDidLeave(() => {
      msgBox.value?.close();
    });

    onMounted(() => {
      usernameText.value = getUsername().value;
      if (route.params.Destination) {
        msgBox.value?.setColor('medium');
        msgBox.value?.showMsg(`A password reset code has been sent to ${route.params.Destination}`);
      }
    });

    const onPasswordScore = (payload: { score: number; strength: string }): void => {
      if (passwordText.value.length === 0) {
        passwordStrength.value = '';
      } else {
        passwordStrength.value =
          payload.strength.charAt(0).toUpperCase() + payload.strength.slice(1);
      }
    };

    watch(passwordText, () => {
      if (passwordText.value.length == 0) {
        passwordStrength.value = '';
      }
    });

    const isValidPasswordResetCode = (code: string): boolean => {
      if (code.length !== 6) {
        return false;
      }
      // Checks if every character is a digit
      for (let i = 0; i < code.length; i++) {
        if (code.charAt(i) < '0' || code.charAt(i) > '9') {
          return false;
        }
      }
      return true;
    };

    const isValidPassword = (password: string): boolean => {
      return password.length >= 8;
    };

    const resendPasswordResetCode = throttle(async (): Promise<void> => {
      msgBox.value?.close();
      msgBox.value?.setColor('danger');

      try {
        const response = await axios.post(
          process.env.VUE_APP_USER_ENDPOINT_URL + '/v1/user/forgotPassword',
          {
            name: usernameText.value,
          },
        );
        if (response.data.Message === 'Request password reset success') {
          msgBox.value?.setColor('medium');
          msgBox.value?.showMsg(
            `A password reset code has been sent to ${response.data.Destination}`,
          );
        } else {
          msgBox.value?.showMsg('Unable to verify: ' + response.data.Message);
        }
      } catch (error:any) {
        if (error.response) {
          msgBox.value?.showMsg('Error: ' + error.response.data.Message);
        } else if (error.request) {
          msgBox.value?.showMsg('Bad request');
        } else {
          msgBox.value?.showMsg('Error: ' + error.message);
        }
      }
    }, 1000);

    const onSubmit = throttle(async (): Promise<boolean> => {
      msgBox.value?.close();
      msgBox.value?.setColor('danger');

      if (!isValidPasswordResetCode(passwordResetCodeText.value)) {
        msgBox.value?.showMsg('Password reset code must be 6 digits');
        return false;
      }
      if (!isValidPassword(passwordText.value)) {
        msgBox.value?.showMsg('Password has to be at least 8 characters');
        return false;
      }
      if (passwordText.value !== confirmPasswordText.value) {
        msgBox.value?.showMsg('Passwords do not match');
        return false;
      }
      try {
        const response = await axios.post(
          process.env.VUE_APP_USER_ENDPOINT_URL + '/v1/user/resetPassword',
          {
            name: usernameText.value,
            password: passwordText.value,
            code: passwordResetCodeText.value,
          },
        );
        if (response.data.Message === 'Password reset success') {
          const toast = await toastController.create({
            header: 'Password reset success!',
            position: 'bottom',
            color: 'success',
            duration: 3000,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
              },
            ],
          });
          toast.present();

          router.push({ name: 'Login' });
        } else {
          msgBox.value?.showMsg('Unable to verify: ' + response.data.Message);
        }
      } catch (error:any) {
        if (error.response) {
          if (
            error.response.data.Message === 'ExpiredCodeException' ||
            error.response.data.Message === 'CodeMismatchException'
          ) {
            msgBox.value?.showMsg('Wrong password reset code');
          } else {
            msgBox.value?.showMsg('Error: ' + error.response.data.Message);
          }
        } else if (error.request) {
          msgBox.value?.showMsg('Bad request');
        } else {
          msgBox.value?.showMsg('Error: ' + error.message);
        }
      }
      return true;
    }, 1000);

    const clickResetPasswordButton = (): void => {
      document.getElementById('resetPasswordButton')?.click();
    };

    return {
      clickResetPasswordButton,
      resendPasswordResetCode,
      onSubmit,
      msgBox,
      usernameText,
      passwordResetCodeText,
      passwordText,
      confirmPasswordText,
      onPasswordScore,
      passwordStrength,
    };
  },
});
